import axios from 'axios'

export interface IACLControls {
  id: number
  subject_type: string
  subject_key: string
  acl_role_id: number
  control: string
  level: number
}

interface IACLServiceProps {
  acl_endpoint: string
  jwtToken: string
}

export class ACLError extends Error {
  constructor(mesage: string) {
    super(mesage)
    this.name = 'ACLError'
  }
}

const USER_INFO = 'user-info'
const LS_ACL_KEY = 'user-acls'

export class ACLService {
  props: IACLServiceProps

  static ACL_INHERIT = 0
  static ACL_DENY = 1
  static ACL_READ = 2
  static ACL_EDIT = 3

  constructor(props: IACLServiceProps) {
    this.props = props
  }

  async load(force?: false) {
    if (!this.props.acl_endpoint) {
      return console.error('No endpoint provided')
    }

    if (!this.props.jwtToken) {
      return console.error('No token provided')
    }

    let acls = this.getAcls()

    if (acls && acls?.length > 0 && !force) {
      return
    }

    try {
      const { data: response } = await axios.get(this.props.acl_endpoint, {
        headers: { Authorization: `Bearer ${this.props.jwtToken}` },
        withCredentials: false,
      })

      const acls = response.acl_effective_controls

      localStorage.setItem(USER_INFO, JSON.stringify(response))
      localStorage.setItem(LS_ACL_KEY, JSON.stringify(acls))
    } catch (e) {
      return console.error(`Error when trying to get ACLs ${JSON.stringify(e)}`)
    }
  }

  clear() {
    localStorage.removeItem(LS_ACL_KEY)
    localStorage.removeItem(USER_INFO)
  }

  getAcls(): IACLControls[] {
    let acls = localStorage.getItem(LS_ACL_KEY) || '[]'

    return JSON.parse(acls)
  }
}
