import React, { createContext, useContext, useEffect, useState } from 'react'
import { ACLService, IACLControls } from '../libs/acl'

interface ACLProviderData {
  loading: boolean
  getAcls: () => IACLControls[]
  clear: () => void
}

interface ACLProviderProps {
  children: React.ReactNode
  aclService: ACLService
}

const ACLContext = createContext({} as ACLProviderData)

export const ACLProvider: React.FC<ACLProviderProps> = ({ children, aclService }: ACLProviderProps) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      try {
        await aclService.load()

      } catch (e) {
        console.error(e)
      }

      setLoading(false)
    }

    load()
  }, [aclService])

  return <ACLContext.Provider value={{ loading, getAcls: aclService.getAcls, clear: aclService.clear }}>{children}</ACLContext.Provider>
}

export function useACL() {
  const context = useContext(ACLContext)

  return context
}

export default ACLContext
