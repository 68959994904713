import React, { useEffect } from 'react'
import { useAuthContext } from '../../contexts/auth.context'

const Login: React.FC = () => {
  const { login } = useAuthContext()

  useEffect(() => {
    login()
  }, [login])

  return <p>Not logged in, redirecting...</p>
}

export default Login
