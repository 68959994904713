import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid'

export default function PCRQToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />
    </GridToolbarContainer>
  )
}
