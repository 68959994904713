import React from 'react'
import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from 'react-router-dom'

import Home from '../pages/Home'

import { ACLProvider } from '../contexts/acl'
import { useAuthContext } from '../contexts/auth.context'
import { ACLService } from '../libs/acl'
import { EnvironmentService } from '../libs/environment'
import SetLanguage from '../pages/SetLanguage'

export function AuthenticatedRoutes() {
  const { token } = useAuthContext()

  const { TCC_API_URL } = EnvironmentService.getVars()

  const TM_ACL_ENDPOINT = `${TCC_API_URL}/me`

  const aclService = new ACLService({
    acl_endpoint: TM_ACL_ENDPOINT,
    jwtToken: token,
  })

  return (
    <ACLProvider aclService={aclService}>
      <BrowserRouter>
        <Routes>
          <Route path="/:app">
            <Route
              path=""
              element={<Home />}
            />
            <Route
              path="language/:lang"
              element={<SetLanguage />}
            />
          </Route>

          <Route
            path="*"
            element={<WrappedComponent />}
          />
        </Routes>
      </BrowserRouter>
    </ACLProvider>
  )
}

const WrappedComponent: React.FC = () => {
  const [search] = useSearchParams()
  const { login } = useAuthContext()

  const tokenExpired = search.get('expire')

  if (tokenExpired) {
    login()
  }

  let redirect = '/its'
  const platform = localStorage.getItem('redirect_after_login')

  if (platform) {
    redirect = platform
  }

  return (
    <Navigate
      to={redirect}
      replace
    />
  )
}
