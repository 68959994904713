import React from 'react'
import { useACL } from '../../contexts/acl'

import Loading from '../Loading'

interface ACLWrappedComponentProps {
  children: React.ReactNode
  control_name: string
  permission_level: number
}

function ACLWrappedComponent({ children, control_name, permission_level }: ACLWrappedComponentProps) {
  const { loading, getAcls } = useACL()

  if (loading) return <Loading />

  const ACLS = getAcls()

  let aclObject = ACLS.filter(el => el.control === control_name)[0]

  if (!aclObject || (aclObject && aclObject.level < permission_level)) {
    return <ACLNotAllowed />
  } else {
    return <>{children}</>
  }
}

function ACLNotAllowed() {
  return (
    <div style={{ paddingTop: 30, paddingLeft: 10 }}>
      <h2>Not Authorized</h2>
    </div>
  )
}

export default ACLWrappedComponent
