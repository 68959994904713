import { AuthProvider as AuthProviderPKCE, TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce'
import { AuthProvider } from './contexts/auth.context'
import { EnvironmentService } from './libs/environment'
import { Routes } from './routes'

const { AUTH_CLIENT_ID, ASSO_URL, APP_URL } = EnvironmentService.getVars()

const authConfig: TAuthConfig = {
  clientId: AUTH_CLIENT_ID,
  authorizationEndpoint: `${ASSO_URL}/oauth/authorize`,
  tokenEndpoint: `${ASSO_URL}/oauth/token`,
  logoutRedirect: `${APP_URL}/login`,
  redirectUri: `${APP_URL}/authorize`,
  scope: 'public openid profile email',
  onRefreshTokenExpire(event: TRefreshTokenExpiredEvent) {
    event.logIn()
  },
  decodeToken: false,
}

function App() {
  return (
    <AuthProviderPKCE authConfig={authConfig}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </AuthProviderPKCE>
  )
}

export default App
