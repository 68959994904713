import { IEnvironmentValues } from '.'

const envVars: IEnvironmentValues = {
  ITS_APP_URL: 'https://tygerweb-qa-k8s.gtldev.net',
  ITS_API_URL: 'https://api-command-qa-k8s.gtldev.net/its',
  SNAP_APP_URL: 'https://snap-rails-qa-k8s.gtldev.net',
  SNAP_API_URL: 'https://api-command-qa-k8s.gtldev.net/snap',
  TCC_API_URL: 'https://api-command-qa-k8s.gtldev.net/tcc',
  TCC_APP_URL: 'https://core-qa-k8s.gtldev.net',
  TMTS_API_URL: 'https://api-command-qa-k8s.gtldev.net/tmts',
  APP_URL: 'https://pcrq.docker-local.telmate.cc',
  ASSO_URL: 'https://asso-qa-k8s.gtldev.net',
  AUTH_CLIENT_ID: 'Ca717r2M9cwo1s6GjfqYROKCdmNGxbPU354KpgbMn9o',
  ENV: 'qa',
}

const hosts: string[] = ['pcrq.docker-local.telmate.cc']

export const local = {
  envVars,
  hosts,
}
