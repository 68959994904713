export const translations = {
  en: {
    translation: {
      type: 'Type',
      professional_requests: 'Professional Requests',
      facillity: 'Facility',
      from: 'From',
      to: 'To',
      approval_status: 'Approval Status',
      all: 'All',
      approved: 'Approved',
      denied: 'Denied',
      pending_review: 'Pending Review',
      search: 'Search by term',
      id: 'ID',
      request_date: 'Request Date',
      last_name: 'Visitor Last Name',
      first_name: 'Visitor First Name',
      dob: 'DOB',
      business_phone_number: 'Business Phone #',
      professional_info: 'Professional Info',
      reviewer: 'Reviewer',
      date_reviewed: 'Date Reviewed',
      actions: 'Actions',
      rows_per_page: 'Rows per page',
      no_rows: 'No rows',
    },
  },
  fr: {
    translation: {
      type: 'Le type',
      professional_requests: 'Demandes Professionnelles',
      facillity: 'Installation',
      from: 'De',
      to: 'À',
      approval_status: 'Statut Approuvé',
      all: 'Tout',
      approved: 'Approuvé',
      denied: 'Refusé',
      pending_review: 'En attendant l’examen',
      search: 'Recherche par terme',
      id: 'ID',
      request_date: 'Date Demande',
      last_name: 'Nom Visiteur',
      first_name: 'Prénom Visiteur',
      dob: 'DDN',
      business_phone_number: 'Téléphone Travail #',
      professional_info: 'Info Professionnel',
      reviewer: 'Critique',
      date_reviewed: 'Date Critique',
      actions: 'Actions',
      rows_per_page: 'Lignes par page',
      no_rows: 'Aucune ligne',
    },
  },
  es: {
    translation: {
      type: 'Tipo',
      professional_requests: 'Solicitudes profesionales',
      facillity: 'Institución',
      from: 'Desde',
      to: 'Hasta',
      approval_status: 'Estado de Aprobación',
      all: 'Todos',
      approved: 'Aprobado',
      denied: 'Negado',
      pending_review: 'Revisión Pendiente',
      search: 'Buscar por término',
      id: 'ID',
      request_date: 'Fecha de solicitud',
      last_name: 'Nombres del visitante',
      first_name: 'Apellidos del visitante',
      dob: 'Fecha Nac.',
      business_phone_number: 'Teléfono Negocios #',
      professional_info: 'Info Profesional',
      reviewer: 'Crítico',
      date_reviewed: 'Fecha Crítico',
      actions: 'Acciónes',
      rows_per_page: 'Filas por página',
      no_rows: 'No hay filas',
    },
  },
}
