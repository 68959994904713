import { useAuthContext } from '../contexts/auth.context'
import { AuthenticatedRoutes } from './authenticated.routes'
import { NotAuthenticatedRoutes } from './not-authenticated.routes'

export function Routes() {
  const { authenticated } = useAuthContext()

  if (!authenticated) {
    return <NotAuthenticatedRoutes />
  }

  return <AuthenticatedRoutes />
}
