import { Alert, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ACLWrappedComponent from '../../components/ACLWrappedComponent'
import PCRQToolbar from '../../components/PCRQToolbar'
import { useAuthContext } from '../../contexts/auth.context'
import { isITS } from '../../hooks/use-platform'
import { ACLService } from '../../libs/acl'
import { EnvironmentService } from '../../libs/environment'
import { useITS } from '../../services/its_api'
import { useTMTS } from '../../services/tmts_api'
import DefaultTemplate from '../../templates'
import { isDateRangeValid, isFutureDate } from '../../utils/validators'

const ACL_CONTROL_NAME = 'professional_status_review'
const ACL_PERMISSION_LEVEL_READ = ACLService.ACL_READ
const ACL_PERMISSION_LEVEL_EDIT = ACLService.ACL_EDIT

const { SNAP_APP_URL, TCC_APP_URL } = EnvironmentService.getVars()

type ItsMemberVisitor = {
  createdAt: string
  id: string
  snapUserId: string
  updatedAt: string
}

function Home() {
  const { t } = useTranslation()
  const { token, login } = useAuthContext()

  const [requests, setRequests] = useState([])
  const [facilities, setFacilities] = useState([])
  const [filters, setFilters] = useState({
    facility: '',
    from: DateTime.now().minus({ month: 1 }),
    to: DateTime.now(),
    approvalState: 'pending',
    search: '',
  })
  const [loading, setLoading] = useState(false)
  const [messageAlert, setMessageAlert] = useState(null as { success: boolean; description: string } | null)
  const [userTimeZone, setUserTimeZone] = useState('local')

  const { getProfessionalRequests, patchProfessionalRequests } = useTMTS(token)
  const { getFacilities } = useITS(token)

  function loadProfessionaRequests() {
    if (!filters.facility) {
      return
    }

    setLoading(true)
    getProfessionalRequests(filters.facility)
      .then(res => {
        let requests = res.data.map((profesionalRequest: any) => {
          profesionalRequest.status = profesionalRequest.status.toLowerCase()

          return profesionalRequest
        })

        setRequests(requests)
      })
      .catch(err => {
        console.log(err)

        if (err.response.status === 401) {
          return unauthorizedAlert()
        }
      })
      .finally(() => setLoading(false))
  }

  function unauthorizedAlert() {
    setMessageAlert({ description: "Your session has expired, you'll be redirected...", success: false })

    setTimeout(login, 3000)
  }

  function filteredRequests() {
    return requests.filter((profesionalRequest: any) => {
      return (
        (filters.from === null ||
          DateTime.fromISO(profesionalRequest.createdAt, { zone: userTimeZone }).startOf('day') >= DateTime.fromISO(filters.from.toISO(), { zone: userTimeZone }).startOf('day')) &&
        (filters.to === null ||
          DateTime.fromISO(profesionalRequest.createdAt, { zone: userTimeZone }).endOf('day') <= DateTime.fromISO(filters.to.toISO(), { zone: userTimeZone }).endOf('day')) &&
        (filters.approvalState === 'all' || profesionalRequest.status === filters.approvalState) &&
        (filters.search === '' ||
          profesionalRequest.user.firstName?.toLowerCase().includes(filters.search.toLowerCase()) ||
          profesionalRequest.user.lastName?.toLowerCase().includes(filters.search.toLowerCase()))
      )
    })
  }

  useEffect(() => {
    loadProfessionaRequests()

    getFacilities()
      .then(res => {
        const { data } = res

        setFacilities(data.facilities)
        setFilters({ ...filters, facility: data.current_facility?.id.toString() })
      })
      .catch(err => {
        console.log(err)

        if (err.response.status === 401) {
          return unauthorizedAlert()
        }
      })
  }, [])

  useEffect(() => {
    // load timezone
    const userInfo = JSON.parse(localStorage.getItem('user-info') || '{}')
    const timeZone = userInfo.time_zone || 'local'

    console.log('loading timezone...', timeZone)

    setUserTimeZone(timeZone)
  }, [filters, requests])

  useEffect(() => {
    loadProfessionaRequests()
  }, [filters.facility])

  function handleChangeStatus(e: SelectChangeEvent, requestId: string) {
    const status = e.target.value

    enum statusMessages {
      pending = 'Professional Status Request is now Pending',
      approved = 'Professional Status Request has been approved',
      denied = 'Professional Status Request has been denied',
    }

    patchProfessionalRequests(requestId, status).then(() => {
      loadProfessionaRequests()
      setMessageAlert({ success: true, description: statusMessages[status.toString() as keyof typeof statusMessages] })
    })
  }

  function areDatesValid(fromDate: DateTime | null, toDate: DateTime | null) {
    if (!isDateRangeValid(fromDate, toDate)) {
      setMessageAlert({ success: false, description: 'The start date must be less than the end date.' })
      return false
    }
    if (isFutureDate(fromDate) || isFutureDate(toDate)) {
      setMessageAlert({ success: false, description: 'Future dates cannot be selected.' })
      return false
    }
    setMessageAlert(null)
    return true
  }

  function onFacilityChange(event: SelectChangeEvent) {
    setFilters({ ...filters, facility: event.target.value.toString() })
  }

  function onFromChange(newValue: DateTime | null) {
    if (!areDatesValid(newValue, filters.to)) return

    console.log(filters.from.toUTC().startOf('day'), DateTime.fromISO(filters.from.toISO(), { zone: userTimeZone }).toUTC().startOf('day'))

    setFilters({ ...filters, from: newValue || DateTime.now() })
  }

  function onToChange(newValue: DateTime | null) {
    if (!areDatesValid(filters.from, newValue)) return
    setFilters({ ...filters, to: newValue || DateTime.now() })
  }

  function onApprovalStateChange(event: SelectChangeEvent) {
    setFilters({ ...filters, approvalState: event.target.value })
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilters({ ...filters, search: event.target.value })
  }

  function getProfileUrl(element: ItsMemberVisitor) {
    if (!element) {
      // error in TMTS when join user
      return '#'
    }

    const lang = localStorage.getItem('language') || 'en'

    const visitorId = isITS() ? element.snapUserId : element.id

    const SNAP_ENDPOUNT = `${SNAP_APP_URL}/admin/members/${visitorId}/edit?locale=${lang}`
    const TCC_ENDPOINT = `${TCC_APP_URL}/ui#/members/visitors/${visitorId}`

    return isITS() ? SNAP_ENDPOUNT : TCC_ENDPOINT
  }

  function doFormatDateTime(dateTime: string) {
    return DateTime.fromISO(dateTime, { zone: userTimeZone }).toFormat('ff')
  }

  function doFormatPhoneNumber(phoneNumber: string) {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      let intlCode = match[1] ? '+1 ' : ''

      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }

    return phoneNumber
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('id'), width: 90 },
    {
      field: 'createdAt',
      headerName: t('request_date'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return doFormatDateTime(params.row.createdAt)
      },
    },
    {
      field: 'LastName',
      headerName: t('last_name'),
      renderCell: (params: GridRenderCellParams) => {
        return <a href={getProfileUrl(params.row.ItsMemberVisitor)}> {params.row.user.lastName} </a>
      },
    },
    {
      field: 'FirstName',
      headerName: t('first_name'),
      renderCell: (params: GridRenderCellParams) => {
        return <a href={getProfileUrl(params.row.ItsMemberVisitor)}> {params.row.user.firstName} </a>
      },
    },
    {
      field: 'BirthdayAge',
      headerName: t('dob'),
      valueGetter: params => {
        return params.row.user.birthday
      },
    },
    {
      field: 'businessPhoneNumber',
      headerName: t('business_phone_number'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.businessPhoneNumber) return doFormatPhoneNumber(params.row.businessPhoneNumber)
      },
    },
    {
      field: 'ProfessionalInfo',
      headerName: t('professional_info'),
      valueGetter: params => {
        return `${params.row.stateOfIssue} ${params.row.barNumber} ${params.row.dateOfIssue}`
      },
      flex: 1,
    },
    {
      field: 'reviewerName',
      headerName: t('reviewer'),
      flex: 1,
    },
    {
      field: 'reviewedAt',
      headerName: t('date_reviewed'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.reviewedAt) return doFormatDateTime(params.row.reviewedAt)
      },
    },
    {
      field: 'FacilityLabel',
      headerName: t('facillity'),
      flex: 1,
      valueGetter: params => {
        return params.row.facility?.label
      },
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        enum statusColor {
          pending = 'yellow',
          approved = 'green',
          denied = 'red',
        }

        const color = statusColor[params.row.status as keyof typeof statusColor]

        return (
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <a href={getProfileUrl(params.row.ItsMemberVisitor)}> View </a>

            <br />

            <ACLWrappedComponent
              control_name={ACL_CONTROL_NAME}
              permission_level={ACL_PERMISSION_LEVEL_EDIT}
            >
              <FormControl
                fullWidth
                sx={{ backgroundColor: color }}
                size="small"
              >
                <Select
                  value={params.row.status}
                  onChange={e => handleChangeStatus(e, params.id.toString())}
                >
                  <MenuItem value="pending">Pending Review</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="denied">Denied</MenuItem>
                </Select>
              </FormControl>
            </ACLWrappedComponent>
          </Box>
        )
      },
    },
  ]

  return (
    <DefaultTemplate>
      <ACLWrappedComponent
        control_name={ACL_CONTROL_NAME}
        permission_level={ACL_PERMISSION_LEVEL_READ}
      >
        <Paper sx={{ mt: 3, p: 2 }}>
          {messageAlert && (
            <Alert
              severity={messageAlert.success ? 'success' : 'error'}
              sx={{ mb: 2 }}
              onClose={() => {
                setMessageAlert(null)
              }}
            >
              {' '}
              {messageAlert.description}{' '}
            </Alert>
          )}
          <Card
            variant="outlined"
            sx={{ mb: 2 }}
          >
            <CardContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Chip label={t('type') + ': ' + t('professional_requests')} />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                  >
                    <FormControl fullWidth>
                      <InputLabel>{t('facillity')}</InputLabel>
                      <Select
                        value={filters.facility}
                        onChange={onFacilityChange}
                        label={t('facillity')}
                      >
                        {facilities?.map((facility: any) => (
                          <MenuItem
                            key={facility.id}
                            value={facility.id}
                          >
                            {facility?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                  >
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <Stack
                        direction="row"
                        spacing={3}
                      >
                        <DesktopDatePicker
                          label={t('from')}
                          inputFormat="MM/dd/yyyy"
                          value={filters.from}
                          onChange={onFromChange}
                          renderInput={params => <TextField {...params} />}
                        />
                        <DesktopDatePicker
                          label={t('to')}
                          inputFormat="MM/dd/yyyy"
                          value={filters.to}
                          onChange={onToChange}
                          renderInput={params => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                  >
                    <FormControl fullWidth>
                      <InputLabel>{t('approval_status')}</InputLabel>
                      <Select
                        value={filters.approvalState}
                        label={t('approval_status')}
                        onChange={onApprovalStateChange}
                      >
                        <MenuItem value="all">{t('all')}</MenuItem>
                        <MenuItem value="pending">{t('pending_review')}</MenuItem>
                        <MenuItem value="approved">{t('approved')}</MenuItem>
                        <MenuItem value="denied">{t('denied')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      variant="outlined"
                      label={t('search')}
                      fullWidth
                      value={filters.search}
                      onChange={onSearchChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Box sx={{ height: '65vh', width: '100%' }}>
            <DataGrid
              disableColumnFilter
              rows={filteredRequests()}
              columns={columns}
              components={{
                Toolbar: PCRQToolbar,
              }}
              density="comfortable"
              loading={loading}
            />
          </Box>
        </Paper>
      </ACLWrappedComponent>
    </DefaultTemplate>
  )
}

export default Home
