const ITS_PLATFORM_PATHNAME = '/its'
const TCC_PLATFORM_PATHNAME = '/tcc'

export function isITS() {
  return window.location.pathname.search(ITS_PLATFORM_PATHNAME) === 0
}

export function isTCC() {
  return window.location.pathname.search(TCC_PLATFORM_PATHNAME) === 0
}
