import axios from 'axios'
import { EnvironmentService } from '../libs/environment'

const { ITS_API_URL } = EnvironmentService.getVars()

let TOKEN: string = ''

function getFacilities() {
  return axios.get(`${ITS_API_URL}/itsapi/navapi/layout_data_json?token=${TOKEN}`)
}

export function useITS(token: string) {
  TOKEN = token

  return {
    getFacilities,
  }
}
