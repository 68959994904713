import React, { createContext, PropsWithChildren, useContext } from 'react'
import { AuthContext as AuthContextPKCE, IAuthContext } from 'react-oauth2-code-pkce'
import { EnvironmentService } from '../libs/environment'

type AuthContextProps = {
  authenticated: boolean
  login: () => void
  logout: () => void
  token: string
}

const AuthContext = createContext({} as AuthContextProps)

const { ASSO_URL, APP_URL } = EnvironmentService.getVars()

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { logIn, idToken } = useContext<IAuthContext>(AuthContextPKCE)

  function manuallyLogout() {
    localStorage.clear()
    window.location.href = `${ASSO_URL}/single_sign_out?redirect_uri=${APP_URL}`
  }

  const contextValues = {
    authenticated: !!idToken,
    login: logIn,
    logout: manuallyLogout,
    token: idToken || '',
  }

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>
}

export const useAuthContext = (): AuthContextProps => useContext(AuthContext)
